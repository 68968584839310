import { Button, Header, Input, Toast } from "oialbert-ui";
import { useHistory } from "react-router";

import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import * as z from "zod";
import useAuth from "../../hooks/useAuth";
import useForm from "../../hooks/useForm";
import { useMaskHandler } from "../../hooks/useMaskHandler";
import useWallet from "../../hooks/useWallet";
import { formatInCents } from "../../utils/money";
import { useOrders } from "../Orders/context";
import { Checkin } from "./components/types";

import { useIonAlert } from "@ionic/react";
import classNames from "classnames";
import { LayoutBase } from "../../components/LayoutBase";
import { useOrderContext } from "../../contexts/OrdersContext";
import { checkInOrder, deliverOrder } from "../../services/orders";
import StepModal from "./components/StepModal";
import { Customer } from "./types";
import { useCompany } from "../../hooks/useCompany";
import { api } from "../../services/config";
import PromotionCard from "./components/PromotionCard";
import "./checkin.css";

type AssociateProps = {
  customer: {
    document_number: string;
    full_name: string;
  };
  cashback: {
    company_id: string;
    wallet_id: string;
    gratifications: {
      total_value: string;
      wallet_id: string;
    }[];
    min_purchase_value: number;
    sum_total_value: number;
    total_value: string;
    release_date: Date;
  };
  gratifications: {
    total_value: string;
    wallet_id: string;
  }[];
  promotions: {
    id: string;
    name: string;
    description: string;
    banner: string;
    discount_amount: number;
  }[];
};

type Distribuition = {
  total_cashback_percent_offered_to_customer: number;
  total_value_in_cents_offered_to_customer: number;
  total_cashback_percent_offered_to_network: number;
  total_value_in_cents_offered_to_network: number;
  total_cashback_percent_offered: number;
  total_value_in_cents_offered: number;
};

const CheckIn = () => {
  const history = useHistory();
  const [openStepModal, setOpenStepModal] = useState<boolean>(false);
  const [associate, setAssociate] = useState<AssociateProps | any>(null);
  const [client, setClient] = useState<Customer | null>(null);
  const [isUsedCashback, setIsUsedCashback] = useState<boolean>(true);
  const [cashbackUsed, setCashbackUsed] = useState<number>();
  const [promotion, setPromotion] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setDistribuition] = useState<Distribuition>({
    total_cashback_percent_offered_to_customer: 0,
    total_value_in_cents_offered_to_customer: 0,
    total_cashback_percent_offered_to_network: 0,
    total_value_in_cents_offered_to_network: 0,
    total_cashback_percent_offered: 0,
    total_value_in_cents_offered: 0,
  });

  const [documentErrorValidation, setDocumentErrorValidation] =
    useState<boolean>(false);
  const [documentErrorStatus, setDocumentErrorStatus] = useState<number | null>(
    0
  );
  const [cpfIsValid, setCpfIsValid] = useState<string | null>('');
  const [identifier, setIdentifier] = useState<string>('');
  const [details, setDetails] = useState<boolean>(false);
  const { showConfirmationModal, setOrderId } = useOrders();
  const [loading, setLoading] = useState<boolean>(false);
  const { company, user } = useAuth();
  const { getCompany } = useCompany();
  const [clientExist] = useIonAlert();
  const { getWallets } = useWallet();
  const [companyData, setCompanyData] = useState(company);
  const { customer, getCustomer, getPreviewGeneric } = useOrderContext();

  const [mask, setMask] = useState('CPF');
  const [type, setType] = useState('document');

  const customerTax = companyData?.configurations?.customer_tax ?? 0;

  const CheckinSchema: any = useMemo(
    () =>
      z
        .object({
          identifier: z
            .string().nonempty("campo obrigatório"),
          cellphone: z
            .string(),
          document_number: z.string(),
          order_value_in_cents: z
            .number({ required_error: "campo obrigatório" })
            .nonnegative("o valor não pode ser negativo")
            .min(1, "o valor do pedido deve ser no mínimo R$ 1,00")
            .max(
              1000000000,
              "o valor do pedido deve ser no máximo R$ 1.000.000,00"
            ),
          cashback_in_percent: z
            .number({
              invalid_type_error: `${
                companyData?.cashback_default_in_percent ??
                Number(companyData?.minCashback)
              }% é o valor mínimo`,
            })
            .gt(
              (Number?.(companyData?.cashback_default_in_percent) ??
                Number(companyData?.minCashback)) - 1,
              `${
                Number(companyData?.cashback_default_in_percent) ??
                Number(companyData?.minCashback)
              }% é o valor mínimo`
            )
            .lt(71, "70% é o valor máximo"),
          cashback_in_cents: z.number({ required_error: "campo obrigatório" }),
        })
        .refine((data) => type === 'cellphone' || isValidCPF(data?.identifier??''), {
          message: 'cpf inválido',
          path: ['identifier']
        }),

    [companyData, documentErrorValidation, type]
  );

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    getValues,
  } = useForm<Checkin>({
    mode: "onChange",
    schema: CheckinSchema,
    defaultValues: {
      order_value_in_cents: 0,
      cashback_in_cents: 0,
      cashback_in_percent:
        Number?.(companyData?.cashback_default_in_percent) ?? 0,
    },
  });

  const orderValueProps = useMaskHandler<Checkin>(
    "order_value_in_cents",
    control,
    0
  );

  const percent = watch("cashback_in_percent") || 0;

  const price = watch("order_value_in_cents") / 100 || 0;

  const percentToPrice = useMemo(() => percent * price, [price, percent]);

  const cashbackUserValue = useMemo(
    () => (percent * price) / 100,
    [percent, price]
  );

  const isCashback = useMemo(
    () =>
      customer?.cashback &&
      customer?.cashback !== 0 &&
      Number(customer?.cashback.total_value) > 0,
    [customer]
  );

  const isGratifications = useMemo(
    () =>
      customer?.gratifications &&
      customer?.gratifications?.length > 0 &&
      Number(customer?.gratifications[0].total_value) > 0,
    [customer]
  );

  const minCashback = useMemo(
    () =>
      Number(companyData?.minCashback) ? Number(companyData?.minCashback) : 0,
    [companyData]
  );

  const atualizeCompany = useCallback(async () => {
    if (company && !company?.configurations) {
      const response = await getCompany(company.id);
      setCompanyData(response?.company);
    } else {
      setCompanyData(company);
    }
  }, [company, getCompany]);

  useEffect(() => {
    atualizeCompany();
  }, [atualizeCompany, company]);

  const totalCashback = useMemo(
    () => (cashbackUserValue * customerTax) / 100,
    [cashbackUserValue, customerTax]
  );

  const cashbackUsedInCents = useMemo(
    () => (cashbackUsed ? cashbackUsed : 0),
    [cashbackUsed]
  );

  useEffect(() => {
    console.log(documentErrorStatus);
    if (documentErrorStatus === 404) {
      setDocumentErrorValidation(true);
    }
    if (documentErrorStatus !== 404) {
      setDocumentErrorValidation(false);
    }
  }, [documentErrorStatus]);

  useEffect(() => {
    setValue("cashback_in_cents", percentToPrice);
  }, [percentToPrice, setValue]);

  useEffect(() => {
    if (promotion) setValue("cashback_in_percent", promotion.discount_amount);
  }, [promotion, setValue]);

  useEffect(() => {
    getWallets();
  }, [getWallets]);

  const getDistribuition = useCallback(
    async (price: number, cashback_percent: number) => {
      const { data } = await api.get<Distribuition>(
        `/v2/partners/cashback/compute/distribution`,
        {
          params: {
            order_cashback_percent: cashback_percent,
            order_in_cents: price,
          },
        }
      );
      setDistribuition(data);
    },
    []
  );

  useEffect(() => {
    getDistribuition(price, percent);
  }, [price, percent, getDistribuition]);

  const onSubmit = useCallback(
    async (data: Checkin) => {
      setLoading(true);
      if (isUsedCashback && (isCashback || isGratifications)) {
        data.used_cashback_in_cents = Number(cashbackUsedInCents.toFixed(0));
      } else {
        delete data.used_cashback_in_cents;
      }
      delete data.cashback_in_cents;

      const preview = await getPreviewGeneric({
        document_number: data.document_number,
        cellphone: data.cellphone,
        order_value_in_cents: +data.order_value_in_cents,
        cashback_in_percent: +data.cashback_in_percent,
        used_cashback_in_cents: true,
      });

      try {
        await checkInOrder(
          data.cellphone,
          data.document_number,
          Math.round(Math.abs(data.order_value_in_cents) ?? 0),
          !(user && ["pdv", "delivery"].includes(user?.partner?.user_types)) ||
            user?.company_partner?.is_able_to_update_checkin_cashback ||
            user?.company_partner?.is_able_to_update_checkin_cashback === null
            ? data.cashback_in_percent
            : null,
          data.used_cashback_in_cents,
          preview,
          promotion?.id
        ).then(async (response) => {
          const preOrder = response?.preOrder;
          const orderId = response?.order_id;

          if (preOrder) {
            history.push("/check-in-resume");
          }

          if (orderId) {
            setOrderId(orderId);
          }
          
          try {
            if (!response?.order_id) {
              Toast.crema("ocorreu um erro ao confirmar pedido");
              return false;
            }
            await deliverOrder(response?.order_id, response?.code);
            history.push("/check-in-resume");
          } catch (error: any) {
            Toast.crema(error.response.data.errors[0].message);
          }
        });

        showConfirmationModal(true);
        setAssociate(null);
        setClient(null);
      } catch (error: any) {
        const {
          response: { data },
        } = error;
        setLoading(false);
        setOpenStepModal(false);
        if (data) {
          Toast.crema(data.message);
        } else {
          Toast.crema("ocorreu um erro ao realizar o checkin");
        }
      } finally {
        setLoading(false);
      }
    },
    [
      isUsedCashback,
      isCashback,
      isGratifications,
      getPreviewGeneric,
      cashbackUsedInCents,
      showConfirmationModal,
      documentErrorValidation,
      history,
      setOrderId,
    ]
  );

  const handleIdentifier = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const identifier = e.target.value.replace(/\D/g, '');
      const validateCpf = isValidCPF(identifier);

      if (identifier.length >= 11) {
        setType(validateCpf === false && /^(1[1-9]|[2-9][0-9])9/.test(identifier) ? 'cellphone' : 'document');
        setMask(validateCpf === false && /^(1[1-9]|[2-9][0-9])9/.test(identifier) ? 'CELLPHONE' : 'CPF');
        setCpfIsValid(validateCpf || /^(1[1-9]|[2-9][0-9])9/.test(identifier) ? null : 'cpf inválido');
      } else {
        setCpfIsValid(null);
      }

      setValue(validateCpf === false && /^(1[1-9]|[2-9][0-9])9/.test(identifier) ? 'cellphone' : 'document_number', identifier);
      setValue(validateCpf === false && /^(1[1-9]|[2-9][0-9])9/.test(identifier) ? 'document_number' : 'cellphone', '');

      setIdentifier(identifier);
      if (identifier?.length >= 11) {
        try {
          const customer = await getCustomer(identifier);
          setAssociate(customer);
          setClient(customer);
          if (customer.customer) {
            clientExist({
              header: "Cliente Cadastrado!",
              message: "O cliente já possui cadastro no albert e você está no lugar certo. Para continuar com o lançamento da venda, informe o valor da compra e finalize o pedido.",
              buttons: ["OK"],
            });
          }

          if (customer?.customer) {
            setDocumentErrorStatus(null);
          }
          return;
        } catch (error: any) {
          if (error?.response?.status === 404) {
            clientExist({
              header: 'Informação',
              message: 'Cliente não possui cadastro no Albert',
              buttons: ['OK'],
            });

            setDocumentErrorStatus(error?.response?.status);
            setAssociate(null);
            setClient(null);
          }
        }
      }
      setAssociate(null);
      setClient(null);
    },
    [clientExist, getCustomer, type]
  );

  const handleModal = useCallback(async () => {
    setLoading(true);
    const data = getValues();
    await getPreviewGeneric({
      document_number: data.document_number,
      cellphone: data.cellphone,
      order_value_in_cents: +data.order_value_in_cents,
      cashback_in_percent: data.cashback_in_percent,
      used_cashback_in_cents: true,
    });
    setOpenStepModal(true);
    setLoading(false);
  }, [getPreviewGeneric, getValues]);

  return (
    <LayoutBase
      title="Check-in"
      header={
        <section className="mt-10 px-5 pb-5">
          <Header onClick={() => history.push("/")} title="Check-in" />
        </section>
      }
    >
      <form>
        <section className="mt-5 px-5">
          <h3 className="font-sm text-sm text-gray-500 mb-220,00">
            digite o cpf ou telefone do cliente
          </h3>
          <div className="mb-4">
            <Input
              {...register('identifier', {
                setValueAs: (value) => value.replace(/[_\.-]/g, ''),
              })}
              onChange={handleIdentifier}
              error={errors.identifier?.message?.toString()}
              mask={mask}
              inputMode="numeric"
              placeholder={type === 'document' ? "___.___.___-__" : "(__) _____-____"}
              className="border border-gray-800 focus:ring-primary-500 focus:border-primary-500"
            />
            {cpfIsValid !== null ? (
              <p className="text-coral-500">{cpfIsValid}</p>
            ) : null}
          </div>

          {client && (
            <>
              <div className="opacity-50">
                <h3 className="font-sm text-sm text-gray-500 mb-220,00">
                  nome do cliente
                </h3>
                <div className="mb-4">
                  <Input
                    inputMode="text"
                    className="border border-gray-800 focus:ring-primary-500 focus:border-primary-500"
                    disabled
                    value={client.customer.full_name??'Não informado'}
                  />
                </div>
              </div>
            </>
          )}

          {associate?.promotions?.length > 0 && (
            <div>
              <h3 className="font-sm text-sm text-gray-500 mb-220,00">
                escolha uma promoção
              </h3>
              <div className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 max-w-7xl gap-4">
                {associate.promotions.map((promo: any) => {
                  return (
                    <PromotionCard
                      promotion={promo}
                      selected={promo.id === promotion?.id}
                      select={setPromotion}
                      key={`key-promotion-${promo.id}`}
                    />
                  );
                })}
              </div>
            </div>
          )}
          
          {type === 'document' &&
          documentErrorValidation &&
          identifier.length >= 11 &&
          cpfIsValid === null ? (
            <div className="w-full h-full border border-crema-500 rounded px-4 py-5 mb-4">
              <div className="flex items-center gap-x-4 mb-4">
                <span className="text-sm text-gray-500">
                  para ativar o seu cliente, por favor, insira o número de
                  telefone dele.
                </span>
              </div>
              <div className="mb-4">
                <Input
                  type="tel"
                  {...register("cellphone", {
                    required: "campo obrigatório",
                    setValueAs: (value) => value.replace(/[_(\.\ )-]/g, ""),
                  })}
                  error={errors.cellphone?.message?.toString()}
                  mask="CELLPHONE"
                  inputMode="numeric"
                  placeholder="(00) 0 0000-0000"
                  className="border border-gray-800 focus:ring-primary-500 focus:border-primary-500"
                />
              </div>
            </div>
          ) : null}

          <section className="my-2 pb-6 border-b border-crema-500">
            <h3 className="font-sm text-sm text-gray-500">
              preço total da compra
            </h3>
            <Input
              type="tel"
              placeholder="0,00"
              inputMode="numeric"
              mask="MONEY"
              className="bg-white px-3 rounded-none shadow-none text-xl text-gray-500 px-0 placeholder-gray-500::placeholder"
              moneyProps={{
                ...orderValueProps,
                prefix: "",
              }}
              error={errors.order_value_in_cents?.message?.toString()}
              {...register("order_value_in_cents")}
            />
          </section>

          <section
            className={classNames(
              "mt-4 mb-5",
              details && !openStepModal ? "block" : "hidden"
            )}
          >
            <h3 className="text-base font-bold text-neon-700 mb-2">
              cálculo do cashback
            </h3>

            <section className="grid grid-cols-2 gap-4 mb-4">
              <div className="mb-4 w-full">
                <h4 className="text-sm text-gray-500 mb-2">cashback %</h4>
                <Input
                  type="number"
                  {...register("cashback_in_percent", {
                    valueAsNumber: true,
                  })}
                  inputMode="numeric"
                  disabled={promotion?.discount_amount}
                  error={errors.cashback_in_percent?.message?.toString()}
                  className={`bg-white font-bold ${
                    promotion?.discount_amount
                      ? "border-gray-100 text-gray-300"
                      : "text-gray-500"
                  }`}
                  placeholder="0%"
                />
              </div>
              <div
                className={classNames(
                  errors.cashback_in_percent?.message && "mb-11",
                  "mb-4 flex flex-col justify-center w-full"
                )}
              >
                <h4 className="text-sm text-gray-500 mb-2">
                  cashback <span className="text-sm uppercase">R$</span>
                </h4>
                <span
                  className={classNames(
                    "h-12 text-center items-center flex px-4 uppercase border rounded-md border-gray-100 w-full text-gray-300"
                  )}
                >
                  {formatInCents(cashbackUserValue)}
                </span>
              </div>
            </section>

            <div className="h-px bg-crema-500 mt-4"></div>
            <li className="flex mt-2">
              <p className="font-bold text-gray-900 text-sm flex-grow">total</p>

              <p className="text-gray-500 text-sm font-bold uppercase">
                {formatInCents(totalCashback)}
              </p>
            </li>
          </section>

          {!details && !openStepModal ? (
            <section className="mt-4">
              <h3 className="text-base font-bold text-neon-900 mb-4">
                pagamento
              </h3>

              <div className="flex mb-4">
                <p className="font-bold text-gray-700 text-sm flex-grow">
                  o associado receberá:
                </p>

                <p className="text-sm font-bold text-gray-500  uppercase">
                  {formatInCents(totalCashback)}
                </p>
              </div>
            </section>
          ) : null}

          <section className="flex sm:flex-row flex-col">
            <footer className="flex flex-wrap space-y-2 pb-3 sm:w-1/2">
              {associate && (isCashback || isGratifications) ? (
                <Button
                  type="button"
                  onClick={handleModal}
                  full
                  normal
                  variant="solid"
                  color="neon"
                  disabled={
                    openStepModal ||
                    cpfIsValid !== null ||
                    price <= 0 ||
                    identifier?.length < 11 ||
                    percent > 70 ||
                    percent < minCashback ||
                    !percent ||
                    loading ||
                    price === 1000000000 ||
                    price >= 1000000000
                  }
                >
                  {loading ? "aguarde..." : "realizar pedido"}
                </Button>
              ) : (
                <Button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  full
                  normal
                  variant="solid"
                  color="neon"
                  disabled={
                    openStepModal ||
                    cpfIsValid !== null ||
                    price <= 0 ||
                    identifier?.length < 11 ||
                    percent > 70 ||
                    percent < minCashback ||
                    !percent ||
                    loading ||
                    price === 1000000000 ||
                    price >= 1000000000
                  }
                >
                  {loading ? "aguarde..." : "realizar pedido"}
                </Button>
              )}
            </footer>
            {!(
              user && ["pdv", "delivery"].includes(user?.partner?.user_types)
            ) ||
            user?.company_partner?.is_able_to_update_checkin_cashback ||
            user?.company_partner?.is_able_to_update_checkin_cashback ===
              null ? (
              <button
                className="text-center sm:w-1/2 hover:underline text-sm text-gray-300"
                type="button"
                disabled={openStepModal}
                onClick={() => setDetails(!details)}
              >
                {!details ? "check-in avançado" : "check-in rápido"}
              </button>
            ) : null}
          </section>
        </section>
        <StepModal
          usedDiscountInCents={
            isCashback
              ? associate?.cashback?.sum_total_value
              : Number(associate?.gratifications[0]?.total_value)
          }
          minPurchaseValue={
            isCashback
              ? associate?.cashback?.min_purchase_value
              : associate?.gratifications[0]?.min_purchase_value
          }
          isUsedCashback={isUsedCashback}
          loading={loading}
          totalOrderInCents={price}
          openModal={openStepModal}
          setIsUsedCashback={setIsUsedCashback}
          setCashbackUsed={setCashbackUsed}
          setOpenModal={setOpenStepModal}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      </form>
    </LayoutBase>
  );
};

export default CheckIn;

import { IonReactRouter } from "@ionic/react-router";
import Login from "../pages/Login";
import Authenticator from "../pages/Login/Authenticator";
import Home from "../pages/Home";
import SelectCompany from "../pages/SelectCompany";
import WalletProvider from "../contexts/WalletContext";
import Promotions from "../pages/Promotions";
import { Route } from "./Route";
import LoginMaster from "../pages/LoginMaster";
import TokenValidator from "../pages/Login/TokenValidator";
import RecoveryPassword from "../pages/RecoveryPassword";
import { MessagesProvider } from "../pages/PergunteAlbert/MessagesContext";
import { Switch } from "react-router";
import PergunteAlbertAIChat from "../pages/PergunteAlbertAI/chat";
import PergunteAlbert from "../pages/PergunteAlbert";
import ResetPassword from "../pages/ResetPassword";
import { ChatProvider } from "../pages/PergunteAlbertAI/ChatContext";
import PergunteAlbertAI from "../pages/PergunteAlbertAI";
import Notifications from "../pages/Notifications";
import { OrdersProvider } from "../pages/Orders/context";
import Gallery from "../pages/Gallery";
import Settings from "../pages/Settings";
import Users from "../pages/Users";
import { CartProvider } from "../pages/ExtraServices/context";
import ExtraServices from "../pages/ExtraServices";
import ExtraServicesCheckout from "../pages/ExtraServicesCheckout";
import OpeningHours from "../pages/OpeningHours";
import Cashback from "../pages/Cashback";
import User from "../pages/User";
import BulkEditOpeningHours from "../pages/OpeningHours/BulkEdit";
import EditOpeningHours from "../pages/OpeningHours/Edit";
import CreateCompany from "../pages/CreateCompany";
import CompanyProfile from "../pages/CompanyProfile";
import OrderContextProvider from "../contexts/OrdersContext";
import CheckIn from "../pages/CheckIn";
import CheckInResume from "../pages/CheckInResume";
import FinancialOrders from "../pages/FinancialOrders";
import TicketList from "../pages/Financial/components/TicketList";
import Financial from "../pages/Financial";
import Balance from "../pages/Balance";
import Withdraw from "../pages/Withdraw";
import Receipt from "../pages/Receipt";
import Cards from "../pages/Cards";
import TokenApi from "../pages/TokenApi";
import Card from "../pages/Card";
import AddPaymentForm from "../pages/Cards/AddPaymentForm";
import Invoices from "../pages/Invoices";
import Profile from "../pages/Profile";
import Menus from "../pages/Menus";
import Items from "../pages/Items";
import CreateItem from "../pages/CreateItem";
import EditItem from "../pages/EditItem";
import Offers from "../pages/Offers";
import CreateOffer from "../pages/CreateOffer";
import EditOffer from "../pages/EditOffer";
import Terms from "../pages/Terms";
import MyTerm from "../pages/MyTerm";
import OnboardingPaymentConfirm from "../pages/OnboardingPayment/Confirm";
import OnboardingPayment from "../pages/OnboardingPayment";
import Onboarding from "../pages/Onboarding";
import Guides from "../pages/Guides/Guides";
import CreatePromotion from "../pages/CreatePromotion";
import { Reports } from "../pages/Reports";
import PlanPayment from "../pages/PlanPayment";
import PlanPaymentSuccess from "../pages/PlanPaymentSuccess";
import ExtraServicesCheckoutSuccess from "../pages/ExtraServicesCheckoutSuccess";
import CashbacksExpired from "../pages/CashbacksExpired";
import HomePDV from "../pages/HomePDV";
import HomeDelivery from "../pages/HomeDelivery";
import useAuth from "../hooks/useAuth";

const HomeTypes: any = {
  default: Home,
  admin: Home,
  pdv: HomePDV,
  delivery: HomeDelivery,
};

export function Routes() {
  const { user } = useAuth();
  return (
    <IonReactRouter>
      <Route exact path={"/login"} component={Login} />
      <Route exact path={"/admin/login"} component={LoginMaster} />
      <Route
        exact
        path={"/authenticator/:document"}
        component={Authenticator}
      />
      <Route exact path={"/validate-token"} component={TokenValidator} />
      <Route exact path={"/recovery-password"} component={RecoveryPassword} />
      <Route exact path={"/select-company"} component={SelectCompany} />

      <MessagesProvider>
        <Switch>
          <Route
            exact
            path={"/pergunte-ao-albert"}
            component={PergunteAlbert}
          />
          <Route
            exact
            path={"/pergunte-ao-albert/chat"}
            component={PergunteAlbertAIChat}
          />
        </Switch>
      </MessagesProvider>

      <Route
        exact
        path={"/reset-password/:email/:token"}
        component={ResetPassword}
      />

      <ChatProvider>
        <Route exact path={"/ai/prompts"} component={PergunteAlbertAI} />
        <Route exact path={"/ai/chat"} component={PergunteAlbertAIChat} />
      </ChatProvider>

      <Route exact path={"/create-company"} component={CreateCompany} />
      <Route exact path={"/notifications"} component={Notifications} />

      <OrdersProvider>
        <Route exact path={"/orders"} component={Home} />
        <Route path={"/orders/:id"} component={Home} />
      </OrdersProvider>

      <Route exact path={"/gallery"} component={Gallery} />
      <Route exact path={"/settings"} component={Settings} />
      <Route exact path={"/users"} component={Users} />

      <CartProvider>
        <Route exact path={"/extra-services"} component={ExtraServices} />
        <Route
          exact
          path={"/extra-services/checkout/:token"}
          component={ExtraServicesCheckout}
        />
      </CartProvider>

      <Route exact path={"/opening-hours"} component={OpeningHours} />
      <Route
        exact
        path={"/opening-hours/edit/:day"}
        component={EditOpeningHours}
      />
      <Route
        exact
        path={"/opening-hours/edit"}
        component={BulkEditOpeningHours}
      />
      <Route exact path={"/cashback"} component={Cashback} />
      <Route path={"/users/:id"} component={User} />
      <Route exact path={"/company-profile"} component={CompanyProfile} />

      <WalletProvider>
        <OrdersProvider>
          <OrderContextProvider>
            <Route exact path={"/check-in"} component={CheckIn} />
            <Route exact path={"/check-in-resume"} component={CheckInResume} />
          </OrderContextProvider>
        </OrdersProvider>

        <Route
          exact
          path={"/"}
          component={(props) => {
            const RenderHome =
              HomeTypes[user?.partner?.user_types ?? "default"];
            return <RenderHome {...props} />;
          }}
        />
        <Route exact path={"/financial-order"} component={FinancialOrders} />
        <Route exact path={"/financial/ticket-list"} component={TicketList} />
        <Route exact path={"/financial"} component={Financial} />
        <Route exact path={"/balance"} component={Balance} />
        <Route exact path={"/withdraw"} component={Withdraw} />
        <Route exact path={"/receipt"} component={Receipt} />
        <Route exact path={"/bank-account"} component={Home} />
      </WalletProvider>

      <Route exact path={"/promotions"} component={Promotions} />

      <Route exact path={"/cards"} component={Cards} />
      <Route exact path={"/token"} component={TokenApi} />
      <Route exact path={"/card"} component={Card} />
      <Route path={"/card/:id"} component={Card} />
      <Route path={"/card/paymentMethod/add"} component={AddPaymentForm} />
      <Route exact path={"/invoices"} component={Invoices} />
      <Route exact path={"/profile"} component={Profile} />
      <Route exact path={"/menus"} component={Menus} />
      <Route exact path={"/categories/:id/products"} component={Items} />
      <Route exact path={"/products/create"} component={CreateItem} />
      <Route exact path={"/products/:id/edit"} component={EditItem} />
      <Route exact path={"/offers"} component={Offers} />
      <Route exact path={"/offers/create"} component={CreateOffer} />
      <Route exact path={"/offers/:id/edit"} component={EditOffer} />
      <Route exact path={"/terms"} component={Terms} />
      <Route exact path={"/my-term"} component={MyTerm} />
      <Route
        exact
        path={"/onboarding/:companyId/confirm"}
        component={OnboardingPaymentConfirm}
      />
      <Route
        exact
        path={"/onboarding/:companyId/payment"}
        component={OnboardingPayment}
      />
      <Route exact path={"/onboarding/:companyId"} component={Onboarding} />
      <Route exact path={"/guides"} component={Guides} />
      <Route path={"/guides/:slug"} component={Guides} />
      <Route exact path={"/cashbacks/expired"} component={CashbacksExpired} />
      <Route exact path={"/promotions"} component={Promotions} />
      <Route exact path={"/promotions/create"} component={CreatePromotion} />
      <Route exact path={"/reports"} component={Reports} />
      <Route exact path={"/plan/payment"} component={PlanPayment} />
      <Route
        exact
        path={"/plan/payment/success"}
        component={PlanPaymentSuccess}
      />
      <Route
        exact
        path={"/services-extras/products"}
        component={ExtraServices}
      />
      <Route
        exact
        path={"/services-extras/checkout/success"}
        component={ExtraServicesCheckoutSuccess}
      />
    </IonReactRouter>
  );
}

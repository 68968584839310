/* eslint-disable @typescript-eslint/no-unused-expressions */
import { IonContent, IonPage } from "@ionic/react";
import { Button, Input, Radio } from "oialbert-ui";
import cep from "cep-promise";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useAuth from "../../hooks/useAuth";
import useForm from "../../hooks/useForm";
import { cpf, cnpj } from "cpf-cnpj-validator";
import * as z from "zod";
import errorHandling from "../../utils/error_handling";
import { useCompany } from "../../hooks/useCompany";
import { Controller } from "react-hook-form";
import { isValidPhoneNumber } from "../../utils/validate-phone-number";

const OnboardingPaymentConfirm = () => {
  const history = useHistory();
  const { companyId }: { companyId: string } = useParams();

  const { user } = useAuth();
  const { company, loadingCompany, getCompany } = useCompany();

  const schema = z.object({
    name: z.string().nonempty({ message: "campo obrigatório" }),
    email: z
      .string()
      .email("e-mail inválido")
      .nonempty({ message: "campo obrigatório" }),
    phone: z
      .string()
      .nonempty({ message: "campo obrigatório" })
      .refine((value) => isValidPhoneNumber(value), {
        message: "número inválido",
      }),
    document_number: z
      .string()
      .nonempty({ message: "campo obrigatório" })
      .refine((value) => {
        if (cpf.isValid(value) || cnpj.isValid(value)) {
          return true;
        }

        return false;
      }, "documento inválido"),
    address: z.object({
      zip_code: z.string().nonempty({ message: "campo obrigatório" }),
      street: z.string().nonempty({ message: "campo obrigatório" }),
      street_number: z
        .string()
        .regex(/^(\s*|\d+)$/)
        .nonempty({ message: "campo obrigatório" }),
      neighborhood: z.string().nonempty({ message: "campo obrigatório" }),
      city: z.string().nonempty({ message: "campo obrigatório" }),
      state: z.string().nonempty({ message: "campo obrigatório" }),
    }),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: user?.partner.full_name ?? "",
      document_number: "",
      email: user?.partner.email ?? "",
      phone: "",
      address: {
        zip_code: "",
        neighborhood: "",
        city: "",
        state: "",
        street: "",
        street_number: "",
      },
    },
    schema,
    mode: "onChange",
  });

  schema.pick({ phone: true }).safeParse({
    phone: getValues("phone"),
  }).success;

  const field = watch();
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [usingCompanyAddress, setUsingCompanyAddress] =
    useState<boolean>(false);

  const searchZipCode = useCallback(async () => {
    setLoadingAddress(true);
    try {
      const dataZipcode = await cep(field?.address?.zip_code);
      setValue("address.neighborhood", dataZipcode.neighborhood);
      setValue("address.city", dataZipcode.city);
      setValue("address.state", dataZipcode.state);
      setValue("address.street", dataZipcode.street);
      setValue("address.street_number", "");
    } catch (err) {
      errorHandling(null, "não foi possível encontrar o cep", "crema");
    } finally {
      setLoadingAddress(false);
    }
  }, [field?.address?.zip_code, setValue]);

  useEffect(() => {
    getCompany(companyId);
  }, [companyId, getCompany]);

  useEffect(() => {
    if (usingCompanyAddress && company?.address) {
      setValue(
        "address.zip_code",
        company?.address?.zip_code ?? company?.address?.zipcode ?? ""
      );
      setValue("address.neighborhood", company.address.neighborhood ?? "");
      setValue("address.city", company.address.city ?? "");
      setValue("address.state", company.address.state ?? "");
      setValue("address.street", company.address.street ?? "");
      setValue(
        "address.street_number",
        company?.address?.street_number?.toString() ?? ""
      );

      // eslint-disable-next-line array-callback-return
      Object.keys(company?.address).map((value) => {
        trigger(`address.${value}` as any);
      });
    }
  }, [usingCompanyAddress, company?.address, setValue, trigger]);

  return (
    <IonPage>
      <IonContent>
        <section className="pt-8 p-5">
          <h1 className="font-bold text-lg">
            olá,{" "}
            <span className="text-neon-900">{user?.partner?.full_name}</span>
          </h1>
          <h2 className="text-base">
            por favor, confirme seus dados cadastrais
          </h2>

          <form
            onSubmit={handleSubmit((data) => {
              history.push({
                pathname: `/onboarding/${companyId}/payment`,
                state: {
                  ...data,
                  subscription: company?.subscriptions[0],
                },
              });
            })}
          >
            <section className="flex flex-col mt-6 mb-10 space-y-4">
              <Input
                required
                placeholder="nome completo"
                disabled={loadingCompany}
                error={errors.name?.message?.toString()}
                {...register("name")}
              />
              <Input
                required
                type="email"
                placeholder="email"
                disabled={loadingCompany}
                error={errors.email?.message?.toString()}
                {...register("email")}
              />
              <Input
                required
                type="tel"
                placeholder="(dd) + telefone"
                mask="CELLPHONE"
                disabled={loadingCompany}
                error={errors.phone?.message?.toString()}
                {...register("phone", {
                  setValueAs: (value) => value.replace(/[^0-9]/g, ""),
                })}
              />
              <Input
                required
                type="tel"
                placeholder="CPF ou CNPJ"
                disabled={loadingCompany}
                maxLength={14}
                error={errors.document_number?.message?.toString()}
                {...register("document_number")}
              />
            </section>
            <h3 className="font-bold text-base">endereço de cobrança</h3>
            <h4 className="text-xs text-gray-500">
              igual ao do cartão de crédito
            </h4>
            <section className="flex flex-col mt-6 space-y-4">
              <section className="flex flex-1 rounded py-2 items-center justify-center border border-gray-500">
                <Radio
                  label="utilizar o mesmo endereço da empresa"
                  id="creditCard"
                  name="type"
                  value="true"
                  titleClassName="font-base font-light text"
                  onChange={(e) =>
                    setUsingCompanyAddress(JSON.parse(e.target.value))
                  }
                  disabled={loadingCompany}
                />
              </section>
              <Controller
                name="address.zip_code"
                control={control}
                rules={{ required: true }}
                render={({ field: { ...props } }) => (
                  <Input
                    {...props}
                    type="tel"
                    placeholder="CEP"
                    mask="CEP"
                    error={errors.address?.zip_code?.message?.toString()}
                    disabled={loadingCompany}
                    onBlur={(e) =>
                      e.target.value.length === 9 && searchZipCode()
                    }
                  />
                )}
              />
              <section className="flex space-x-4">
                <Input
                  required
                  placeholder="Endereço"
                  disabled={loadingAddress || loadingCompany}
                  error={errors.address?.street?.message?.toString()}
                  {...register("address.street")}
                />
                <section className="w-20">
                  <Input
                    required
                    placeholder="nº"
                    disabled={loadingAddress || loadingCompany}
                    error={errors.address?.street_number?.message?.toString()}
                    {...register("address.street_number")}
                  />
                </section>
              </section>
              <Input
                required
                placeholder="bairro"
                disabled={loadingAddress || loadingCompany}
                error={errors.address?.neighborhood?.message?.toString()}
                {...register("address.neighborhood")}
              />
              <section className="flex space-x-4">
                <Input
                  required
                  placeholder="cidade"
                  disabled={loadingAddress || loadingCompany}
                  error={errors.address?.city?.message?.toString()}
                  {...register("address.city")}
                />
                <section className="w-20">
                  <Input
                    required
                    placeholder="uf"
                    disabled={loadingAddress || loadingCompany}
                    error={errors.address?.state?.message?.toString()}
                    {...register("address.state")}
                  />
                </section>
              </section>
            </section>

            <section className="w-full mt-6">
              <Button
                full
                variant="solid"
                color="neon"
                disabled={!isValid || loadingCompany}
              >
                próximo
              </Button>
            </section>
          </form>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default OnboardingPaymentConfirm;

import { Dialog, Transition } from "@headlessui/react";
import { Button } from "oialbert-ui";
import { Fragment } from "react";

interface EditPromotionConfirmationModalProps {
  isOpen: boolean;
  close: () => void;
  onConfirm: () => void;
  loading: boolean;
}

const EditPromotionConfirmationModal = ({ isOpen, close, onConfirm,loading }: EditPromotionConfirmationModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md bg-white rounded-2xl p-6 shadow-xl">
            <Dialog.Title className="text-lg font-medium text-gray-900">Editar promoção</Dialog.Title>
            
            <p className="mt-4 text-base text-center text-gray-600">
              Hey! Sua edição está sendo analisada. A gente te avisa quando a promoção for aprovada ou recusada!
            </p>
            
            <section className="grid sm:grid-cols-2 gap-4 mt-8">
              <Button color="neon"  variant="outline" onClick={close}>Cancelar</Button>
              <Button color="neon"  variant="solid" onClick={() => {
                onConfirm();
                close();
              }} disabled={loading}>Ok</Button>
            </section>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditPromotionConfirmationModal;
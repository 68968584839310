/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-useless-escape */
import { IonContent, IonPage } from "@ionic/react";
import { useCallback } from "react";

import { Button, Input } from "oialbert-ui";

import LogoWhite from "../../assets/logo-white.svg";
import PatternLogo from "../../assets/pattern-logo.svg";

import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import useAuth from "../../hooks/useAuth";

import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import * as z from "zod";

const schema = z
  .object({
    document_number: z.string().nonempty({ message: "campo obrigatório" }),
  })
  .refine((data) => isValidCPF(data.document_number), {
    message: "cpf inválido",
    path: ["document_number"],
  });

const LoginMaster = () => {
  const { sendToken, loading } = useAuth();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      document_number: "",
      admin_document_number: "",
    },
    ...schema,
    mode: "onChange",
  });

  function removeSpecialCharacters(document: string) {
    return document?.replace(/[-_\./]/gi, "");
  }

  const onSubmit = useCallback(
    async (formData) => {
      const document_number = formData.document_number?.replace(
        /[-_\./]/gi,
        ""
      );
      const admin_document_number = removeSpecialCharacters(
        formData.admin_document_number
      );
      const status: any = sendToken(document_number, admin_document_number);
      if (status !== 404 && status !== 500) {
        history.push(`/authenticator/${document_number}`);
      }
    },
    [sendToken, history]
  );

  return (
    <IonPage>
      <IonContent>
        <section className="bg-gray-900 h-full flex flex-col items-center justify-between relative z-0">
          <img
            src={PatternLogo}
            className="h-60 absolute top-0 right-0 pointer-events-none"
            alt="Pattern Logo"
            style={{ zIndex: -1 }}
          />
          <section className="sm:mx-auto sm:w-full sm:max-w-xl flex-1 flex flex-col items-center justify-center px-5 space-y-5">
            <img src={LogoWhite} className="h-20" alt="Logo" />

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-full flex flex-col gap-8"
            >
              <Controller
                control={control}
                name="document_number"
                render={({ field: { onChange, ...rest } }) => (
                  <Input
                    inputMode="numeric"
                    type="text"
                    label="número do documento do parceiro"
                    placeholder="insira o CPF ou CNPJ"
                    disabled={loading}
                    autoComplete="off"
                    required
                    onChange={onChange}
                    error={errors.document_number?.message?.toString()}
                    tabIndex={0}
                    mode="dark"
                    maxLength={18}
                    {...rest}
                  />
                )}
              />
              <Controller
                control={control}
                name="admin_document_number"
                render={({ field: { onChange, ...rest } }) => (
                  <Input
                    inputMode="numeric"
                    type="text"
                    label="número do documento do admin"
                    placeholder="insira o CPF ou CNPJ"
                    disabled={loading}
                    autoComplete="off"
                    required
                    onChange={onChange}
                    error={errors.document_number?.message?.toString()}
                    tabIndex={0}
                    mode="dark"
                    maxLength={18}
                    {...rest}
                  />
                )}
              />
              <section className="w-full mt-5">
                <Button
                  type="submit"
                  full
                  variant="solid"
                  color="neon"
                  disabled={loading && isValid}
                  tabIndex={0}
                >
                  {loading ? "aguarde..." : "entrar"}
                </Button>
              </section>
            </form>
          </section>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default LoginMaster;

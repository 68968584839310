import { Button, Input, Select } from "oialbert-ui";
import { useCallback, useEffect, useState } from "react";
import * as z from "zod";
import useAuth from "../../../hooks/useAuth";
import { useCompany } from "../../../hooks/useCompany";
import useForm from "../../../hooks/useForm";
import { CompanyDetailsData } from "../../../types/companies";
import errorHandling from "../../../utils/error_handling";
import { profile_types } from "../../../utils/texts";
import Category from "../../CompanyProfile/components/Category";
import { Description } from "../../CompanyProfile/components/Description";

type ProfileType = {
  data: CompanyDetailsData | null;
  dataTemp: any;
  setDataTemp: (_payload: any) => void;
  onNext: () => void;
};

const schema = z.object({
  profile_type: z.string().nonempty({ message: "campo obrigatório" }),
  name: z.string().nonempty({ message: "campo obrigatório" }),
});

const Profile = ({ data, onNext, setDataTemp }: ProfileType) => {
  const [categoryModalVisible, setCategoryModalVisible] =
    useState<boolean>(false);

  const [selectedSubcategories, setSelectedSubcategories] = useState<
    { title: string; id: string }[]
  >(data?.subcategories ?? []);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      ...data,
      profile_type: "products",
    },
    schema,
  });
  useEffect(() => {
    register("description", { value: "" });
  }, [register]);

  const field = watch();

  const { setCompany } = useAuth();
  const { updateCompany } = useCompany();

  const { address, id, ...restData } = data ?? {};

  const handleUpdate = useCallback(
    async (payloadData) => {
      try {
        await updateCompany(
          {
            id,
            profile_type: payloadData?.profile_type,
            description: field.description,
            name: field.name,
            subcategories: selectedSubcategories.map((sub: any) => sub.id),
          },
          (companyData: any) => {
            setCompany({
              ...companyData,
              cashback_default_in_percent:
                companyData?.cashback_default_in_percent,
            });
            setDataTemp({
              ...companyData,
              cashback_default_in_percent:
                companyData?.cashback_default_in_percent,
            });
            onNext();
          }
        );
      } catch (err) {
        errorHandling(err, "erro ao atualizar empresa", "crema");
      }
    },
    [
      address,
      id,
      onNext,
      restData,
      selectedSubcategories,
      setCompany,
      setDataTemp,
      updateCompany,
    ]
  );

  return (
    <section className="sm:mx-auto sm:w-full sm:max-w-xl flex flex-col items-center justify-between px-6 mt-6 space-y-12">
      <section className="w-full flex flex-col mx-auto mx-4">
        <Select
          required
          label=""
          id="profile"
          placeholder="perfil da loja"
          options={Object.keys(profile_types).map((p) => ({
            label: profile_types[p],
            value: p,
          }))}
          {...register("profile_type")}
        />
        <section className="mt-4">
          <Input
            required
            placeholder="nome fantasia (nome que aparece no app)"
            error={errors.name?.message?.toString()}
            {...register("name")}
          />
        </section>
        <section className="mt-4">
          <Description
            value={field.description}
            onChange={(description) => setValue("description", description)}
          />
        </section>
        <section className="mt-4">
          <button
            type="button"
            className="form-input h-12 focus:ring-neon-500 focus:border-neon-500 block w-full sm:text-sm border-gray-500 rounded flex items-center"
            onClick={() => setCategoryModalVisible(true)}
          >
            <span>
              {selectedSubcategories.length
                ? selectedSubcategories
                    ?.map((subcategory: any) => subcategory.title)
                    .join(", ")
                : "selecionar categorias"}
            </span>
          </button>

          <Category
            onClose={() => setCategoryModalVisible(false)}
            open={categoryModalVisible}
            onSelect={(subcategories) =>
              subcategories.length && setSelectedSubcategories(subcategories)
            }
            values={selectedSubcategories}
          />
        </section>
      </section>
      <Button
        full
        type="button"
        disabled={!isValid || selectedSubcategories.length <= 0}
        variant="solid"
        color="neon"
        onClick={handleSubmit(handleUpdate)}
      >
        próximo
      </Button>
    </section>
  );
};

export default Profile;
